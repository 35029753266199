<template>
  <div style="margin-bottom: max(9vh, 5vw)">
    <div class="ytm-landing-font ytm-landing-chapter-head">
      Частые вопросы
    </div>
    <Question v-for="(q, i) in questions" :key="i" v-bind="q"/>
  </div>
</template>

<script>
import Question from "./Question";

export default {
  name: 'FAQ',
  components: {Question},
  data() {
    return {
      questions: [
        {
          question: 'В чём отличие YouTeachMe от онлайн-школы?',
          answer: 'Онлайн-школа – это работодатель, а <b>YouTeachMe</b> – это сервис по подписке, сохраняющий все преимущества частного репетиторства и облегчающий работу преподавателя с помощью современных интерактивных инструментов',
        },
        {
          question: 'Как происходит оплата за занятие?',
          answer: 'Как и при обычном онлайн-занятии – по договорённости с учеником. Через YouTeachMe не проходят оплаты, мы просто предоставляем инструмент для проведения занятий',
        },
        {
          question: 'Сколько стоит использование YouTeachMe?',
          answer: 'Для учеников использование платформы бесплатно – оплата происходит напрямую преподавателю.<br><br>Для преподавателей оплата раз в месяц по подписке. У нас есть три тарифа – начинающий, продвинутый и профессионал. Подробнее о тарифах можно узнать <a href="/tariffs" style="color: #A23DF1" target="_blank">здесь</a>.<br><br>Ах да, у нас есть <b>бесплатный пробный период длиной 30 дней</b> для преподавателей 👉👈',
        },
        {
          question: 'Какие предметы можно вести на YouTeachMe?',
          answer: 'Любые :)<br><br>На <b>YouTeachMe</b> нет ограничений по предмету или возрасту ученика, главное – удобство проведения урока',
        },
        {
          question: 'Есть ли мобильное приложение?',
          answer: 'Нет, но скоро появится бот в Telegram, с помощью которого можно будет получать уведомления и планировать свою работу на <b>YouTeachMe</b>',
        },
        {
          question: 'Есть ли у вас возможность найти ученика?',
          answer: 'Пока такой возможности нет, но мы работаем над ней 🚀',
        },
        {
          question: 'Можно ли проводить групповые занятия?',
          answer: 'Да, можно в тарифе <a href="/tariffs" style="color: #A23DF1" target="_blank">“Профессионал”</a>',
        },
        {
          question: 'Какие инструменты я могу использовать при проведении урока?',
          answer: 'Подробнее об возможностях платформы можно узнать в секции <a href="/features" style="color: #A23DF1" target="_blank">“Возможности”</a>',
        },
        {
          question: 'Есть ли встроенная видеосвязь?',
          answer: 'Пока что мы предлагаем использовать Google Meet или Zoom, но скоро и у нас появится видеосвязь 🎉',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>