<template>
  <div style="position: relative; width: 100%">
    <div class="ytm-landing-head-desc">
      <div class="ytm-landing-head-desc-top">
        <span class="ytm-landing-font ytm-landing-head-desc-top-dot">.</span>
        <span class="ytm-landing-font ytm-landing-head-desc-top-text">YouTeachMe</span>
      </div>
      <div class="ytm-landing-font ytm-landing-head-desc-middle">
        ТВОЙ ИДЕАЛЬНЫЙ<br>ИНСТРУМЕНТ ДЛЯ<br>ОНЛАЙН-ЗАНЯТИЙ
      </div>
      <div class="ytm-landing-font ytm-landing-head-desc-bottom">
        Всё самое необходимое<br>на одной платформе
      </div>
      <div class="ytm-landing-head-buttons-wrapper">
        <div class="ytm-landing-head-join">
          <button class="ytm-landing-head-button-join" @click="$router.push('/register')">
          <span v-if="!is_auth" class="ytm-landing-font ytm-landing-head-button-join-text" style="margin-right: 1vw">
            Попробовать бесплатно!
          </span>
            <span v-else class="ytm-landing-font ytm-landing-head-button-join-text" style="margin-right: 1vw">
            Перейти к занятиям!
          </span>
            <svg style="width: max(1.77vw, 0.9rem)" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 13.3679C0.895431 13.3679 8.19842e-08 14.2634 0 15.3679C-8.19842e-08 16.4725 0.89543 17.3679 2 17.3679L2 13.3679ZM33.1296 16.7821C33.9106 16.0011 33.9106 14.7348 33.1296 13.9537L20.4016 1.22579C19.6206 0.444737 18.3543 0.444737 17.5732 1.22579C16.7922 2.00683 16.7922 3.27316 17.5732 4.05421L28.8869 15.3679L17.5732 26.6816C16.7922 27.4627 16.7922 28.729 17.5732 29.5101C18.3543 30.2911 19.6206 30.2911 20.4016 29.5101L33.1296 16.7821ZM2 17.3679L31.7153 17.3679L31.7153 13.3679L2 13.3679L2 17.3679Z" fill="white"/>
            </svg>
          </button>
          <div v-if="!is_auth" class="ytm-landing-head-join-free-text">
            <div class="ytm-landing-font ytm-landing-free" style="display: table-cell; vertical-align: middle">
              30 дней бесплатно
            </div>
          </div>
        </div>
        <button v-if="screen_width > 450" class="ytm-landing-head-button-learn-more" @click="$emit('goto', 'about')">
          <span class="ytm-landing-font ytm-landing-head-button-learn-more-text" style="margin-right: 1vw">
            Узнать больше
          </span>
          <svg style="width: max(1.77vw, 0.9rem)" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 13.3679C0.895431 13.3679 8.19842e-08 14.2634 0 15.3679C-8.19842e-08 16.4725 0.89543 17.3679 2 17.3679L2 13.3679ZM33.1296 16.7821C33.9106 16.0011 33.9106 14.7348 33.1296 13.9537L20.4016 1.22579C19.6206 0.444737 18.3543 0.444737 17.5732 1.22579C16.7922 2.00683 16.7922 3.27316 17.5732 4.05421L28.8869 15.3679L17.5732 26.6816C16.7922 27.4627 16.7922 28.729 17.5732 29.5101C18.3543 30.2911 19.6206 30.2911 20.4016 29.5101L33.1296 16.7821ZM2 17.3679L31.7153 17.3679L31.7153 13.3679L2 13.3679L2 17.3679Z" fill="#A23DF1"/>
          </svg>
        </button>
      </div>
    </div>
    <img alt="boy" src="boy.webp" style="object-fit: contain" class="ytm-landing-head-image"/>
  </div>
</template>

<script>
import {inject} from "vue";

export default {
  name: 'LandingHead',
  computed: {
    is_auth() {
      return this.$store.getters['auth/isAuthenticated'];
    },
  },
  setup() {
    let screen_width = inject('screen_width');
    return {screen_width};
  },
};
</script>

<style scoped>
.ytm-landing-head-desc {
  width: 50%;
  margin-bottom: 20%;
}

.ytm-landing-head-image {
  position: absolute;
  top: -10%;
  right: 0;
  width: 55%;
  height: 130%;
  z-index: -50;
}

@media only screen and (max-width: 1000px) {
  .ytm-landing-head-image {
    top: 0;
  }
}

@media only screen and (max-width: 700px) {
  .ytm-landing-head-image {
    top: 5%;
  }
}

@media only screen and (max-width: 450px) {
  .ytm-landing-head-image {
    position: relative;
    width: 100%;
    height: 251px;
    margin-top: 15px;
    margin-bottom: 50px;
    background-position: center;
  }
}

.ytm-landing-head-desc-top {
  width: 100%;
  margin-top: 2.5vw;
}

.ytm-landing-head-desc-top-dot {
  font-weight: 700;
  font-size: max(4.5vw, 3rem);
  color: #A23DF1;
}

.ytm-landing-head-desc-top-text {
  margin-left: 0.5vw;
  font-weight: 300;
  font-size: max(1.66vw, 0.8rem);
  color: #191847;
}

.ytm-landing-head-desc-middle {
  margin-top: 15px;
  font-weight: 900;
  font-size: max(3.33vw, 1.1rem);
  color: #191847;
}

.ytm-landing-head-desc-bottom {
  margin-top: 22px;
  font-weight: 500;
  font-size: max(1.5vw, 0.8rem);
  color: rgba(57, 57, 57, 0.87);
}

.ytm-landing-head-buttons-wrapper {
  position: relative;
  width: 50%;
}

.ytm-landing-head-join {
  margin-top: 2.7vw;
  display: flex;
}

.ytm-landing-head-join-free-text {
  display: table;
  margin-left: 1vw;
}

@media only screen and (max-width: 450px) {
  .ytm-landing-head-buttons-wrapper {
    width: 100%;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ytm-landing-head-buttons-wrapper button {
    background: #07A8F0;
    border-color: #07A8F0;
  }

  .ytm-landing-head-join-free-text {
    margin-top: 1vh;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 900px) {
  .ytm-landing-head-join {
    display: inline-block;
  }

  .ytm-landing-head-join-free-text {
    margin-top: 1vh;
  }
}

.ytm-landing-head-button-join {
  background: #A23DF1;
  border: 3px solid #A23DF1;
  border-radius: 20px;
  display: flex;
  padding: 0.6vw 1.2vw;
  cursor: pointer;
}

.ytm-landing-head-button-join-text {
  font-weight: 600;
  font-size: max(1.25vw, 0.8rem);
  color: #FFFFFF;
  white-space: nowrap;
}

.ytm-landing-free {
  font-weight: 500;
  font-size: max(1vw, 0.6rem);
  color: #9747FF;
}

.ytm-landing-head-button-learn-more {
  margin-top: 1.25vw;
  background: transparent;
  border: 3px solid #A23DF1;
  border-radius: 20px;
  display: flex;
  padding: 0.6vw 1.2vw;
  cursor: pointer;
}

.ytm-landing-head-button-learn-more-text {
  font-weight: 600;
  font-size: max(1.25vw, 0.8rem);
  color: #A23DF1;
  white-space: nowrap;
}

@media only screen and (max-width: 450px) {
  .ytm-landing-head-desc {
    text-align: center;
    width: 100%;
    margin-bottom: 0;
  }

  .ytm-landing-head-desc-top {
    margin-top: 0;
  }
}
</style>