<template>
  <div class="ytm-tariff-background">
    <div style="height: 100%; display: flex; flex-flow: column">
      <div class="ytm-landing-font ytm-tariff-name" style="flex-grow: 1">{{tariff_name}}</div>
      <div style="width: 100%; text-align: center; flex-grow: 1">
        <svg class="ytm-tariff-star" width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.0489 0.927052C15.3483 0.0057416 16.6517 0.00573993 16.9511 0.927051L19.8167 9.74671C19.9506 10.1587 20.3346 10.4377 20.7678 10.4377H30.0413C31.0101 10.4377 31.4128 11.6773 30.6291 12.2467L23.1267 17.6976C22.7762 17.9522 22.6295 18.4036 22.7634 18.8156L25.6291 27.6353C25.9284 28.5566 24.874 29.3227 24.0902 28.7533L16.5878 23.3024C16.2373 23.0478 15.7627 23.0478 15.4122 23.3024L7.90976 28.7533C7.12605 29.3227 6.07157 28.5566 6.37092 27.6353L9.2366 18.8156C9.37048 18.4036 9.22382 17.9522 8.87333 17.6976L1.37088 12.2467C0.587169 11.6773 0.989944 10.4377 1.95867 10.4377H11.2322C11.6654 10.4377 12.0494 10.1587 12.1833 9.74671L15.0489 0.927052Z" fill="#A23DF1"/>
        </svg>
      </div>
      <div style="flex-grow: 4">
        <div v-for="(feature, i) in features" :key="i" class="ytm-landing-font ytm-tariff-feature ytm-lh-140">{{feature}}</div>
      </div>
      <div style="text-align: center">
        <span class="ytm-landing-font ytm-tariff-price">{{cost}}</span>
        <span class="ytm-landing-font ytm-rubles">&nbsp₽/меcяц</span>
      </div>
    </div>
  </div>
</template>

<script>
import "./landing.css";

export default {
  name: 'Tariff',
  props: {
    tariff_name: {
      type: String,
      required: true,
    },
    features: {
      type: Array,
      required: true,
    },
    cost: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.ytm-tariff-background {
  height: 100%;
  background: rgba(255, 255, 255, 0.26);
  border: 2px solid rgba(255, 255, 255, 0.21);
  border-radius: 3.3vw;
  padding: 3vw 1.2vw;
}

.ytm-tariff-name {
  text-align: center;
  font-weight: 600;
  font-size: max(1.7vw, 0.7rem);
  color: #000000;
  margin-bottom: 2.8vw;
}

.ytm-tariff-star {
  width: max(1.8vw, 0.8rem);
  height: max(1.8vw, 0.8rem);
  margin-bottom: 1vw;
}

.ytm-tariff-feature {
  text-align: center;
  font-weight: 400;
  font-size: max(1.1vw, 0.5rem);
  color: #000000;
  margin-bottom: 1.5vw;
}

.ytm-tariff-price {
  font-weight: 700;
  font-size: max(2vw, 0.8rem);
  color: #000000;
}

.ytm-rubles {
  font-weight: 400;
  font-size: max(1.1vw, 0.6rem);
  color: #000000;
}
</style>