<template>
  <div style="position: relative; width: 100vw; min-height: 100vh; overflow: hidden">
    <LandingBackground/>
    <div class="ytm-landing-wrapper">
      <LandingTop @scrollToFooter="goto('footer')"/>
      <LandingHead @goto="goto"/>
      <LandingStart ref="start"/>
      <WeAreCool/>
      <Abilities/>
      <Tariffs/>
      <FAQ/>
      <Footer ref="footer"/>
    </div>
  </div>
</template>

<script>
import LandingBackground from "./Background";
import LandingHead from "./Head";
import LandingStart from "./Start";
import LandingTop from "./Navbar";
import WeAreCool from "./WeAreCool";
import Abilities from "./Abilities";
import Footer from "./Footer";
import {inject} from "vue";
import Tariffs from "./Tariffs";
import FAQ from "./FAQ";

export default {
  name: 'LandingPage',
  components: {FAQ, Tariffs, Footer, Abilities, WeAreCool, LandingTop, LandingStart, LandingHead, LandingBackground},
  setup() {
    let screen_width = inject('screen_width');
    return {screen_width};
  },
  methods: {
    goto(ref_name) {
      let element;
      switch (ref_name) {
        case 'about':
          element = this.$refs.start.$refs.about; break;
        case 'footer':
          element = this.$refs.footer.$el; break;
      }
      const top = element.offsetTop;
      window.scrollTo(0, top);
    },
  },
};
</script>

<style>

</style>