<template>
  <div class="ytm-landing-start" style="display: flex" ref="about">
    <div class="ytm-landing-start-smile"/>
    <div style="width: 75%">
      <p class="ytm-landing-font ytm-landing-start-heading ytm-lh-130" style="margin-bottom: 1vw">
        Устали искать материалы перед каждым занятием, тратить время на проверку домашек и контролировать оплаты? С нами легче!
      </p>
      <p class="ytm-landing-font ytm-landing-start-text ytm-lh-140">
        По результатам исследования, репетиторы на YouTeachMe тратят на подготовку к занятию на 30% меньше времени за счёт автоматизации рутинных действий
      </p>
    </div>
  </div>
</template>

<script>
import "./landing.css";

export default {
  name: 'LandingStart',
};
</script>

<style scoped>
.ytm-landing-start {
  background: rgba(255, 255, 255, .55);
  border-radius: 2vw;
  padding: 2vw 3.5vw;
  margin-bottom: max(10vh, 6vw);
}

.ytm-landing-start-smile {
  width: 7.5vw;
  height: 7.5vw;
  margin-right: 2.5vw;
  background-image: url("../../../public/smile.webp");
  background-size: contain;
  background-repeat: no-repeat;
}

.ytm-landing-start-heading {
  font-weight: 600;
  font-size: max(1.25vw, 0.8rem);
  color: #000000;
}

.ytm-landing-start-text {
  font-weight: 400;
  font-size: max(1.1vw, 0.8rem);
  color: #000000;
}
</style>