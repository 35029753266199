<template>
  <div class="ytm-ability">
    <div style="display: table">
      <div style="display: table-cell">
        <div class="ytm-ability-wrapper">
          <img class="ytm-ability-icon" :src="icon"/>
        </div>
      </div>
    </div>
    <div style="display: table">
      <div class="ytm-landing-font ytm-ability-desc ytm-lh-130" style="display: table-cell; vertical-align: middle">
        {{desc}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ability',
  props: {
    icon: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.ytm-ability {
  width: 100%;
  display: flex;
  margin-top: max(1vw, 0.6rem);
  margin-bottom: max(2vw, 1.1rem);
}

.ytm-ability-wrapper {
  position: relative;
  width: max(5vw, 2.5rem);
  height: max(5vw, 2.5rem);
  margin-right: max(2vw, 1rem);
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.25);
  border-radius: 1.8vw 0 1.8vw 1.8vw;
}

.ytm-ability-icon {
  position: absolute;
  top: 20%;
  left: 20%;
  right: 20%;
  bottom: 20%;
  width: 60%;
  height: 60%;
}

.ytm-ability-desc {
  font-weight: 400;
  font-size: max(1.15vw, 0.8rem);
  color: rgba(57, 57, 57, 0.87);
}
</style>