<template>
  <div style="margin-bottom: max(10vh, 6vw)">
    <div class="ytm-landing-font ytm-landing-chapter-head">
      Проводите занятия с нами!
    </div>
    <div class="ytm-landing-font ytm-cool-text ytm-lh-130">
      У нас есть всё, чтобы сделать ваши занятия эффективными, интерактивными и запоминающимися для ученика
    </div>
    <Carousel
        :n_slides="tools.length"
        :per_page="1"
        :pagination="true"
        v-slot="{cur_slide}"
        style="margin-top: 6vh"
    >
      <Slide
          v-for="(tool, idx) in tools"
          :key="idx"
          :per_page="1"
          :cur_slide="cur_slide"
      >
        <div v-if="screen_width > 900" style="display: flex">
          <div style="width: 60%">
            <img :src="tool.url" :alt="tool.title" style="width: 100%; object-fit: cover; border-radius: 1rem"/>
          </div>
          <div style="width: 35%; margin: 1vw 1vw 1vw 5%;display: table">
            <div style="display: table-cell; vertical-align: middle">
              <p class="ytm-landing-font ytm-cool-carousel-title ytm-lh-130">{{tool.title}}</p>
              <p class="ytm-landing-font ytm-cool-carousel-desc ytm-lh-140">{{tool.desc}}</p>
            </div>
          </div>
        </div>
        <div v-else style="display: table; height: 100%">
          <div style="display: table-cell; vertical-align: middle">
            <p class="ytm-landing-font ytm-cool-carousel-title ytm-lh-130" style="text-align: center; margin-bottom: 1rem">{{tool.title}}</p>
            <img :src="tool.url" :alt="tool.title" style="width: 100%; object-fit: cover; border-radius: 1rem"/>
            <p class="ytm-landing-font ytm-cool-carousel-desc ytm-lh-140" style="text-align: center">{{tool.desc}}</p>
          </div>
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import Carousel from "@/components/common/Carousel/Carousel.vue";
import Slide from "@/components/common/Carousel/Slide.vue";
import "./landing.css";
import {inject} from "vue";

export default {
  name: 'WeAreCool',
  components: {Carousel, Slide},
  setup() {
    let screen_width = inject('screen_width');
    return {screen_width};
  },
  data() {
    return {
      tools: [
        {
          title: 'Интерактивные учебные материалы',
          desc: 'Совместный текстовый редактор, встроенная онлайн-доска и множество других инструментов помогут проводить интересные и продуктивные занятия. После окончания занятия все материалы сохраняются',
          url: 'https://storage.yandexcloud.net/youteachme/landing/materials.png',
        },
        {
          title: 'Тесты с автоматической проверкой и разбалловкой',
          desc: 'Составьте вопросы и установите дедлайн – а работы учеников мы проверим за вас',
          url: 'https://storage.yandexcloud.net/youteachme/landing/tests.png',
        },
        {
          title: 'Чат для репетитора и ученика',
          desc: 'Если у ученика возник вопрос по пройденному материалу, ему не придётся тревожить вас посреди ночи – он просто напишет в чат курса',
          url: 'https://storage.yandexcloud.net/youteachme/landing/Screenshot%202023-02-26%20at%2022.22.44.png',
        },
        {
          title: 'Личный кабинет',
          desc: 'Личный кабинет с поддержкой расписания, контроля оплат и задолженностей. Вам больше не придётся учитывать, кто задолжал, а кто заплатил вперёд',
          url: 'https://storage.yandexcloud.net/youteachme/landing/personal.png',
        },
      ],
    };
  },
};
</script>

<style scoped>
.ytm-cool-text {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  text-align: center;
  font-weight: 400;
  font-size: max(1.25vw, 0.8rem);
  color: #393939;
}

.ytm-cool-carousel-title {
  font-weight: 700;
  font-size: max(1.75vw, 1rem);
  color: #393939;
}

.ytm-cool-carousel-desc {
  margin-top: 2vw;
  font-weight: 400;
  font-size: max(1.15vw, 0.8rem);
  color: #393939;
}
</style>