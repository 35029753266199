<template>
  <div style="margin-bottom: max(10vh, 6vw)">
    <div class="ytm-landing-font ytm-landing-chapter-head">
      Тарифы
    </div>
    <div style="width: 100%; display: flex; justify-content: stretch; margin-bottom: max(3vw, 3vh)">
      <div class="ytm-tariff-wrapper ytm-side-tariff" style="width: 30%">
        <Tariff v-bind="novice"/>
      </div>
      <div class="ytm-tariff-wrapper" style="width: 40%">
        <Tariff v-bind="advanced"/>
      </div>
      <div class="ytm-tariff-wrapper ytm-side-tariff" style="width: 30%">
        <Tariff v-bind="professional"/>
      </div>
    </div>
    <div class="ytm-landing-font ytm-more-text">
      Подробнее о тарифах и условиях можно узнать <a href="/tariffs" @click.prevent="$router.push('/tariffs')" style="color: #A23DF1">здесь</a>
    </div>
  </div>
</template>

<script>
import Tariff from "./Tariff";

export default {
  name: 'Tariffs',
  components: {Tariff},
  data() {
    return {
      novice: {
        tariff_name: 'Начинающий',
        features: [
            'Для тех, кто делает первые шаги на YouTeachMe',
            'До 3 активных учеников в месяц',
            'Доступ ко всем инструментам для занятий',
        ],
        cost: 299,
      },
      advanced: {
        tariff_name: 'Продвинутый',
        features: [
            'До 8 активных учеников',
            'Доступ ко всем материалам и инструментам + собственная база вопросов и тестов',
            'Хранилище файлов до 2 ГБ',
        ],
        cost: 699,
      },
      professional: {
        tariff_name: 'Профессионал',
        features: [
          'Неограниченный доступ к возможностям YouTeachMe',
          'Возможность проводить групповые курсы',
          'Хранилище файлов до 15 ГБ',
        ],
        cost: 1299,
      },
    };
  },
};
</script>

<style scoped>
.ytm-tariff-wrapper {
  padding-left: 2%;
  padding-right: 2%;
}

.ytm-side-tariff {
  padding-top: 2%;
  padding-bottom: 2%;
}

.ytm-more-text {
  font-weight: 400;
  font-size: max(1.25vw, 0.8rem);
  color: #000000;
}
</style>