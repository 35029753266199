<template>
  <div class="ytm-landing-question-wrapper">
    <div class="ytm-landing-question" @click="openAnswer">
      <p class="ytm-landing-font ytm-landing-question-text ytm-lh-130">{{question}}</p>
      <div class="ytm-landing-question-arrow">
        <svg viewBox="0 0 58 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.77783 20C2.12098 20 0.777832 21.3431 0.777832 23C0.777832 24.6569 2.12098 26 3.77783 26L3.77783 20ZM56.5716 25.1213C57.7432 23.9498 57.7432 22.0503 56.5716 20.8787L37.4798 1.7868C36.3082 0.615228 34.4087 0.615227 33.2371 1.7868C32.0656 2.95837 32.0656 4.85787 33.2371 6.02944L50.2077 23L33.2371 39.9706C32.0656 41.1421 32.0656 43.0416 33.2371 44.2132C34.4087 45.3848 36.3082 45.3848 37.4798 44.2132L56.5716 25.1213ZM3.77783 26L54.4503 26L54.4503 20L3.77783 20L3.77783 26Z" fill="#07A8F0"/>
        </svg>
      </div>
    </div>
    <p ref="answer" class="ytm-landing-font ytm-landing-question-answer ytm-lh-140" v-html="answer"/>
  </div>
</template>

<script>
export default {
  name: 'Question',
  props: {
    question: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
  },
  data() {
    return { opened: false };
  },
  methods: {
    openAnswer() {
      if (!this.opened) {
        this.$refs.answer.style.maxHeight = '50vh';
        this.$refs.answer.style.overflow = 'auto';
        this.$refs.answer.style.marginTop = 'max(1.25vw, 1rem)';
      } else {
        this.$refs.answer.style.overflow = 'hidden';
        this.$refs.answer.style.marginTop = '0';
        this.$refs.answer.style.maxHeight = '0';
      }
      this.opened = !this.opened;
    },
  },
};
</script>

<style scoped>
.ytm-landing-question-wrapper {
  width: 100%;
  margin-bottom: max(1vw, 2vh);
  padding: max(1vw, 0.5rem) max(2vw, 1rem);
  background: #FFFFFF;
  border-radius: max(1vw, 1vh);
}

.ytm-landing-question {
  width: 100%;
  display: flex;
  cursor: pointer;
}

.ytm-landing-question-text {
  flex-grow: 1;
  font-weight: 400;
  font-size: max(1.25vw, 1rem);
  color: #191847;
  user-select: none;
}

.ytm-landing-question-arrow {
  width: max(2vw, 1.5rem);
  height: max(2vw, 1.5rem);
  min-width: max(2vw, 1.5rem);
  min-height: max(2vw, 1.5rem);
  margin-left: 2vw;
}

.ytm-landing-question-answer {
  max-height: 0;
  overflow: hidden;
  font-weight: 400;
  font-size: max(1.1vw, 0.8rem);
  color: #191847;
  transition: max-height .1s ease;
  -webkit-transition: max-height .1s ease;;
}
</style>