<template>
  <div style="margin-bottom: max(9vh, 5vw)">
    <div class="ytm-landing-font ytm-landing-chapter-head">
      Возможности платформы YouTeachMe
    </div>
    <div v-if="screen_width > 500" style="display: flex">
      <div class="ytm-abil-column" style="width: 50%">
        <Ability v-for="item in left_column" :key="item.icon" v-bind="item"/>
      </div>
      <div class="ytm-abil-column" style="width: 50%">
        <Ability v-for="item in right_column" :key="item.icon" v-bind="item"/>
        <button class="ytm-landing-blue-button" @click="$router.push('/features')" style="display: flex; margin-top: 3.5vw">
          <span class="ytm-landing-font ytm-landing-blue-button-text" style="margin-right: 0.7vw">
            Все возможности платформы
          </span>
          <svg style="width: max(1.5vw, 0.9rem)" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 13.3679C0.895431 13.3679 8.19842e-08 14.2634 0 15.3679C-8.19842e-08 16.4725 0.89543 17.3679 2 17.3679L2 13.3679ZM33.1296 16.7821C33.9106 16.0011 33.9106 14.7348 33.1296 13.9537L20.4016 1.22579C19.6206 0.444737 18.3543 0.444737 17.5732 1.22579C16.7922 2.00683 16.7922 3.27316 17.5732 4.05421L28.8869 15.3679L17.5732 26.6816C16.7922 27.4627 16.7922 28.729 17.5732 29.5101C18.3543 30.2911 19.6206 30.2911 20.4016 29.5101L33.1296 16.7821ZM2 17.3679L31.7153 17.3679L31.7153 13.3679L2 13.3679L2 17.3679Z" fill="white"/>
          </svg>
        </button>
      </div>
    </div>
    <div v-else class="ytm-abil-column">
      <Ability v-for="item in left_column" :key="item.icon" v-bind="item"/>
      <button class="ytm-landing-blue-button" @click="$router.push('/features')" style="display: flex; margin-top: 3.5vw">
          <span class="ytm-landing-font ytm-landing-blue-button-text" style="margin-right: 0.7vw">
            Все возможности платформы
          </span>
        <svg style="width: max(1.5vw, 0.9rem)" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 13.3679C0.895431 13.3679 8.19842e-08 14.2634 0 15.3679C-8.19842e-08 16.4725 0.89543 17.3679 2 17.3679L2 13.3679ZM33.1296 16.7821C33.9106 16.0011 33.9106 14.7348 33.1296 13.9537L20.4016 1.22579C19.6206 0.444737 18.3543 0.444737 17.5732 1.22579C16.7922 2.00683 16.7922 3.27316 17.5732 4.05421L28.8869 15.3679L17.5732 26.6816C16.7922 27.4627 16.7922 28.729 17.5732 29.5101C18.3543 30.2911 19.6206 30.2911 20.4016 29.5101L33.1296 16.7821ZM2 17.3679L31.7153 17.3679L31.7153 13.3679L2 13.3679L2 17.3679Z" fill="white"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import {inject} from "vue";
import Ability from "./Ability";
import "./landing.css";

export default {
  name: 'Abilities',
  components: {Ability},
  setup() {
    let screen_width = inject('screen_width');
    return {screen_width};
  },
  data() {
    return {
      left_column: [
        {
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/e-learning.png',
          desc: 'Удобная система курсов и занятий с расписанием',
        },
        {
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/document--v1.png',
          desc: 'Мощный текстовый редактор с отображением математических формул',
        },
        {
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/whiteboard.png',
          desc: 'Встроенная онлайн-доска с совместным доступом и отображением курсоров',
        },
        {
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/cheque.png',
          desc: 'Возможность выставлять стоимость занятия и контролировать доход',
        },
      ],
      right_column: [
        {
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/code.png',
          desc: 'Совместный редактор программного кода',
        },
        {
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/messaging-.png',
          desc: 'Встроенный чат для общения с учеником и родителем на платформе',
        },
        {
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/survey--v1.png',
          desc: 'Тесты с автоматической проверкой, разбалловкой и дедлайном',
        },
      ],
    };
  },
};
</script>

<style scoped>
.ytm-abil-column {
  padding-left: 2vw;
  padding-right: 2vw;
}
</style>